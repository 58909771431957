<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="8">
                <v-card class="fill-height">
                    <v-card-title>
                        <v-icon left>mdi-chart-bar</v-icon>
                        Calculate your StakeDrop
                    </v-card-title>
                    <v-card-text>
                        <v-divider></v-divider>
                        <StakeDropCalcWalletInput @form-submitted="handleFormSubmission" style="overflow-x: auto;"/>
                        <v-card>
                            <formattedTotalReward :totalReward="totalReward" style="overflow-x: auto;"/>
                            <StakeDropCalcChart :calculationData="calculationData" :price="price" style="overflow-x: auto;"/>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" style="min-width: 300px;">
                <v-card class="fill-height">
                    <v-card-title>
                        <v-icon left>mdi-trophy-variant</v-icon>
                        Top 25 Delegatooors
                    </v-card-title>
                    <v-card-text>
                        25 Delegatooors with highest StakeDrop rewards
                        <v-divider></v-divider>
                    </v-card-text>
                    <div style="overflow-x: auto;">
                        <Top100TabbedTables align="center"/>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="8">
                <v-card class="fill-height">
                    <v-card-title>
                        <v-icon left>mdi-database</v-icon>
                        StakeDrop Transactions
                    </v-card-title>
                    <v-card-text>
                        List of StakeDrop related transactions for your wallet in simplified form
                        <v-divider></v-divider>
                    </v-card-text>
                    <div style="overflow-x: auto;">
                        <DelegationTransactions :transactionData="transactionData" align="center"/>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card class="fill-height">
                    <v-card-title>
                        <v-icon left>mdi-calculator</v-icon>
                        StakeDrop Statistic
                    </v-card-title>
                    <v-card-text>
                        Number of participating Wallets
                        <v-divider></v-divider>
                    </v-card-text>
                    <div style="overflow-x: auto;">
                        <StakeDropStatisticTable1 align="center"/>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="8">
                <v-card class="fill-height">
                    <v-card-title>
                        <v-icon left>mdi-calculator</v-icon>
                        Predict your StakeDrop
                    </v-card-title>
                    <v-card-text>
                        Calculation is based on current prices, APRs and Commission fees
                        <v-divider></v-divider>
                    </v-card-text>
                    <div style="overflow-x: auto;">
                        <StakeDropTable align="center"/>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card class="fill-height">
                    <v-card-title>
                        <v-icon left>mdi-calculator</v-icon>
                        Important Information
                    </v-card-title>
                    <v-divider></v-divider>
                    <div style="overflow-x: auto;">
                        <LastUpdateTable align="center"/>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import StakeDropTable from '@/components/TablePredictStakeDrop.vue';
import StakeDropCalcChart from '@/components/StakeDropCalcChart.vue';
import StakeDropCalcWalletInput from '@/components/StakeDropCalcWalletInput.vue';
import StakeDropStatisticTable1 from '@/components/StakeDropStatistic.vue';
import LastUpdateTable from '@/components/ImportantInformation.vue';
import Top100TabbedTables from '@/components/Top100.vue';
import DelegationTransactions from '@/components/DelegationTransactions.vue';
import formattedTotalReward from '@/components/TotalReward.vue';

export default {
    name: 'StakeDrop',
    components: {
        StakeDropTable,
        StakeDropCalcChart,
        StakeDropCalcWalletInput,
        StakeDropStatisticTable1,
        LastUpdateTable,
        Top100TabbedTables,
        DelegationTransactions,
        formattedTotalReward
    },
    data() {
        return {
            walletAddress: '',
            price: null,
            calculationData: [],
            transactionData: []
        };
    },
    methods: {
        handleFormSubmission(data) {
            this.calculationData = data.calculation_data;
            this.price = data.price;
            this.totalReward = data.total_reward;
            this.transactionData = data.transaction_data;
        }
    }
};
</script>

<style scoped>
.text-box-content {
  color: #ffffff; /* Blue color */
  margin-bottom: 8px;
  font-size: 18px; /* Change this to the desired font size */
}
</style>