<template>
    <v-card>
        <v-card-text>
            <canvas id="StakeDropChart"></canvas>
        </v-card-text>
    </v-card>
</template>

<script>
  import Chart from 'chart.js/auto';
  
  export default {
    name: 'StakeDropCalcChart',
    props: {
      calculationData: {
        type: Array,
        required: true
      },
      price: {
        type: Number,
        required: true
      }
    },
    watch: {
      calculationData() {
        this.renderChart();
      },
      price() {
        this.renderChart();
      }
    },
    mounted() {
      this.renderChart();
    },
    methods: {
    renderChart() {
      const ctx = document.getElementById('StakeDropChart').getContext('2d');
      if (this.chart) {
        this.chart.destroy(); // Destroy the existing chart instance before creating a new one
      }
      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.calculationData.map(entry => entry.date),
          datasets: [
            {
              label: 'Staked Value',
              yAxisID: 'y-axis-1',
              data: this.calculationData.map(entry => entry.staked_value),
              borderColor: 'rgba(255, 200, 0, 1)',
              borderWidth: 1,
              pointRadius: 1,
              tension: 0.4 // Add this line for smooth curves
            },
            {
              label: 'Staking APR',
              yAxisID: 'y-axis-2',
              data: this.calculationData.map(entry => entry.apr),
              borderColor: 'rgba(0, 100, 0, 0.9)',
              borderWidth: 1,
              pointRadius: 1,
              tension: 0.4 // Add this line for smooth curves
            },
            {
              label: 'Combined APR',
              yAxisID: 'y-axis-2',
              data: this.calculationData.map(entry => entry.combined_apr),
              borderColor: 'rgba(0, 255, 0, 1)',
              borderWidth: 2,
              pointRadius: 1,
              tension: 0.4 // Add this line for smooth curves
            },
            {
              label: 'Pryzm Rewards',
              yAxisID: 'y-axis-3',
              data: this.calculationData.map(entry => entry.pryzm_rewards),
              borderColor: 'rgba(0, 191, 255, 1)',
              borderWidth: 2,
              pointRadius: 1,
              tension: 0.4 // Add this line for smooth curves
            }
          ]
        },
        options: {
          scales: {
            'y-axis-2': {
              type: 'linear',
              display: true,
              position: 'left',
              beginAtZero: true,
              title: {
                display: true,
                text: `APR (%), PRYZM Price: ${this.price}$`,
                color: 'rgba(0, 255, 0, 1)'
              },
              grid: {
                drawOnChartArea: false
              }
            },
            'y-axis-1': {
              type: 'linear',
              display: true,
              position: 'left',
              beginAtZero: true,
              title: {
                display: true,
                text: 'Value ($)',
                color: 'rgba(255, 200, 0, 1)'
              }
            },
            'y-axis-3': {
              type: 'linear',
              display: true,
              position: 'right',
              beginAtZero: true,
              title: {
                display: true,
                text: 'Pryzm Tokens',
                color: 'rgba(0, 191, 255, 1)'
              },
              grid: {
                drawOnChartArea: false
              }
            }
          }
        }
      });
    }
  },
  beforeUnmount() {
    if (this.chart) {
      this.chart.destroy(); // Ensure the chart is destroyed when the component is unmounted
    }
  }
};
</script>
  
<style scoped>
/* Add any custom styles here */
</style>
  