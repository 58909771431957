<template>
    <v-form @submit.prevent="submitForm">
        <v-text-field
            v-model="walletAddress"
            label="Enter your StakeDrop wallet address and calculate your current StakeDrop rewards"
            required
        ></v-text-field>
        <v-radio-group inline v-model="price" required>
            <v-card>Pryzm Price Prediction (mandatory!):</v-card>
            <v-card>
                <v-radio
                    v-for="option in priceOptions"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                ></v-radio>
            </v-card>
            <v-spacer></v-spacer>
            <v-btn type="submit" color="primary">Submit</v-btn>
        </v-radio-group>
    </v-form>
</template>

<script>
export default {
    name: 'StakeDropCalcWalletInput',
    data() {
        return {
            walletAddress: '',
            price: null,
            priceOptions: [
                { label: '$0.1', value: 0.1 },
                { label: '$0.5', value: 0.5 },
                { label: '$1', value: 1 },
                { label: '$2', value: 2 },
                { label: '$3', value: 3 }
            ]
        };
    },
    methods: {
        async submitForm() {
            try {
                const response = await fetch('https://pryzmdata.zone/backend/calculate', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        wallet_address: this.walletAddress,
                        price: this.price
                    })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                this.$emit('form-submitted', data);
            } catch (error) {
                console.error('Error submitting form:', error);
                alert('An error occurred. Please check your wallet address (supported wallets: Dymension, Celestia, Cosmos, Terra, Osmosis) and your selection of Pryzm Price.');
            }
        }
    }
};
</script>
