<template>
    <v-container>
        <v-simple-table class="full-width-table">
            <thead>
                <tr class="header-background">
                    <th class="text-left">PRYZM | StakeDrop Validator</th>
                    <th class="text-left">Participating Wallets</th>
                    <th class="text-left">Average PRYZM Rewards</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in items" :key="index" :class="{'total-row': item.chain === 'TOTAL'}">
                    <td class="text-left cell-background">{{ item.chain }}</td>
                    <td class="text-left cell-background">{{ item.wallets }}</td>
                    <td class="text-left cell-background">{{ item.avg_rewards }}</td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-container>
</template>
  
<script>
export default {
    name: 'StakeDropStatisticTable1',
    data() {
        return {
            items: []
        };
    },
    created() {
        // Fetch the data from the external file when the component is created
        fetch('/data.json')
            .then(response => response.json())
            .then(data => {
                this.items = data.stakeDropStatistics;  // Accessing the specific array from the JSON file
            })
            .catch(error => console.error('Error loading data:', error));
    }
}
</script>
  
<style scoped>
.full-width-table {
    width: 100%; /* Set the table width to 100% */
}

th, td {
    text-align: center;
    vertical-align: middle;
    border: 1px solid #3f3f3f; /* Add border to cells */
}

.header-background {
    background-color: #222222;
    color: rgb(153, 112, 0);
}

th {
    padding: 10px;
    width: 300px;
}

.cell-background {
    background-color: #242424; /* Background color for cells */
}

.total-row td {
    background-color: rgb(153, 112, 0); /* Different background color for TOTAL row */
    font-weight: bold;
}
</style>
