<template>
    <v-container>
        <v-simple-table>
            <thead>
                <tr class="header-background">
                    <th class="text-center" colspan="2">Delegated [tokens]</th>
                    <th class="text-center">Staking Duration [days]</th>
                    <th class="text-center">Current Token Price [$]</th>
                    <th class="text-center">Staking APR [%]</th>
                    <th class="text-center">Commission fees [%]</th>
                    <th class="text-center">Pryzm Received [tokens]</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in items" :key="index">
                    <td class="text-center">
                        <v-text-field v-model="item.delegated" type="number" hide-details class="centered-input"></v-text-field>
                    </td>
                    <td class="text-center" style="min-width: 60px;">{{ item.token }}</td>
                    <td class="text-center">
                        <v-text-field v-model="item.duration" type="number" hide-details class="centered-input"></v-text-field>
                    </td>
                    <td class="text-center">{{ item.price }}</td>
                    <td class="text-center">{{ item.apr }}</td>
                    <td class="text-center">{{ item.fees }}</td>
                    <td class="text-center"><span>{{ item.result }}</span></td>
                </tr>
                <tr>
                    <td colspan="5"></td>
                    <td colspan="1" class="text-center">
                        <v-btn color="primary" @click="calculateAllResults">Calculate</v-btn>
                    </td>
                    <td class="text-center"><span class="text-warning">{{ overallResult }}</span></td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-container>
</template>
  
<script>
export default {
    name: 'StakeDropTable',
    data() {
        return {
            items: [],
            overallResult: ''
        };
    },
    created() {
        // Fetch the data from the external file when the component is created
        fetch('/data.json')
            .then(response => response.json())
            .then(data => {
                this.items = data.stakeDropPredictions;
            })
            .catch(error => console.error('Error loading data:', error));
    },
    methods: {
        calculateAllResults() {
            let total = 0;
            this.items.forEach((item) => {
                const res = item.duration * ((item.delegated * item.price * (item.apr / 100) * (item.fees / 100)) / 365);
                item.result = (10 * res).toFixed(2);
                total += parseFloat(item.result);
            });
            this.overallResult = total.toFixed(2);
        }
    }
};
</script>
  
<style scoped>
th, td {
    text-align: center;
    vertical-align: middle;
}

.v-text-field.centered-input {
    text-align: center;
    margin: 0 auto;
    width: 100px;
}

.header-background {
    background-color: #222222;
}

th {
    padding: 10px;
}
</style>
