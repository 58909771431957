<template>
    <v-container>

        <!-- Tabs for selecting tables -->
        <v-tabs v-model="activeTab" background-color="primary" dark>
            <v-tab v-for="(tab, index) in tabs" :key="index">{{ tab.name }}</v-tab>
        </v-tabs>

        <v-tabs></v-tabs>

        <v-simple-table class="full-width-table">
            <thead>
                <tr class="header-background">
                    <th class="text-center">Delegatooor</th>
                    <th class="text-center">PRYZM Rewards</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in activeTabData" :key="index">
                <td class="text-left">{{ maskWallet(row.column1) }}</td>
                <td class="text-center">{{ row.column2 }}</td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-container>

</template>

<script>
export default {
  name: 'Top100TabbedTables',
  data() {
    return {
      activeTab: 0, // Default tab index
      tabs: [], // Will be populated with data from JSON
      headers: [
        { text: "Wallet", value: "column1" },
        { text: "Amount", value: "column2" }
      ]
    };
  },
  computed: {
    activeTabData() {
      return this.tabs[this.activeTab]?.data || [];
    }
  },
  methods: {
    maskWallet(wallet) {
      if (wallet.length > 20) {
        const start = wallet.slice(0, 10);
        const end = wallet.slice(-10);
        return `${start}**********${end}`;
      }
      return wallet;
    }
  },
  created() {
    // Fetch data from the JSON file
    fetch('/data.json')
      .then(response => response.json())
      .then(data => {
        this.tabs = data.tabs || [];
      })
      .catch(error => console.error('Error loading data:', error));
  }
};
</script>

<style scoped>
.full-width-table {
    width: 100%; /* Set the table width to 100% */
}

th, td {
    text-align: center;
    vertical-align: middle;
    border: 1px solid #3f3f3f; /* Add border to cells */
}

.header-background {
    background-color: #222222;
    color: rgb(153, 112, 0);
}

th {
    padding: 10px;
    width: 300px;
}

.cell-background {
    background-color: #242424; /* Background color for cells */
}

.total-row td {
    background-color: rgb(153, 112, 0); /* Different background color for TOTAL row */
    font-weight: bold;
}
</style>