<template>
    <v-container>
      <!-- Table to display the transaction data -->
      <div class="table-wrapper" v-if="transactionData && transactionData.length">
        <v-simple-table class="full-width-table">
          <thead>
            <tr class="header-background">
              <th class="text-center" style="width: 300px;">Date</th>
              <th class="text-center" style="width: 300px;">Stake / Unstake</th>
              <th class="text-center" style="width: 300px;">Token Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, index) in transactionData"
              :key="index"
              :class="row.AMOUNT > 0 ? 'stake-row' : 'unstake-row'"
            >
              <td class="text-left" style="width: 300px;">{{ row.BLOCK_TIMESTAMP }}</td>
              <td class="text-center" style="width: 300px;">{{ row.AMOUNT > 0 ? 'Stake' : 'Unstake' }}</td>
              <td class="text-center" style="width: 300px;">{{ Math.abs(row.AMOUNT) }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
  
      <!-- Fallback if no data is available -->
      <div v-else>
        <h3>No Transaction Data Available</h3>
      </div>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'DelegationTransactions',
    props: {
      transactionData: {
        type: Array,
        required: true
      }
    },
    computed: {
      formattedTransactionData() {
        // Formats the JSON with indentation for better readability
        return JSON.stringify(this.transactionData, null, 2);
      }
    }
  };
  </script>
  
  <style scoped>
  .table-wrapper {
    max-height: 300px;
    overflow-y: auto;
    width: 900px;
  }
  
  .full-width-table {
    width: 900px;
    table-layout: fixed;
  }
  
  th, td {
    text-align: center;
    vertical-align: middle;
    border: 1px solid #3f3f3f;
  }
  
  .header-background {
    background-color: #222222;
    color: rgb(153, 112, 0);
  }
  
  th {
    padding: 10px;
  }
  
  .stake-row {
    color: rgb(118, 241, 118);
  }
  
  .unstake-row {
    color: rgb(255, 35, 35);
  }
  </style>
  