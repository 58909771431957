<template>
    <v-btn
      href="https://wallet.keplr.app/chains/pryzm?modal=staking&chain=pryzm-1&validator_address=pryzmvaloper14gln9w0ljscj4uyj87k2ymwyraf0885n4jf400&step_id=2"
      target="_blank"
      color="primary"
      large
      elevation="10"
      class="wide-button"
    >
      <span class="button-text">
        pryzmdata.zone is PRYZM VALIDATOR. Powered by PRYZM community.
      </span>
    </v-btn>
  </template>
  
  <script>
  export default {
    name: 'WideButton',
  }
  </script>
  
  <style scoped>
  .v-btn.wide-button {
    background: linear-gradient(45deg, #2f576d, #2f576d);
    color: white;
    border-radius: 25px;
    width: 100%;
    max-width: 100%;
    padding: 12px 24px; /* Add padding for better text positioning */
    text-transform: none; /* To keep the text as is */
    white-space: normal; /* To allow text wrapping */
    word-wrap: break-word; /* To handle long words */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .v-btn.wide-button:hover {
    background: linear-gradient(45deg, #478ed1, #42a5f5);
  }
  
  .button-text {
    font-size: 18px;
    color: white; /* Ensure the text color contrasts with the background */
  }

  .v-btn.wide-button {
    margin: 0; /* Center the button horizontally */
    }

    .v-app-bar {
      display: flex;
      justify-content: center; /* Center the content inside the app bar */
      align-items: center;
    }
  
  @media (max-width: 600px) {
    .v-btn.wide-button {
      width: 100%;
      font-size: 14px;
    }
  }
  
  @media (min-width: 601px) and (max-width: 960px) {
    .v-btn.wide-button {
      width: 75%;
      font-size: 16px;
    }
  }
  
  @media (min-width: 961px) {
    .v-btn.wide-button {
      width: 50%;
      font-size: 18px;
    }
  }
  </style>
  