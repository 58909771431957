<!-- src/App.vue -->
<template>
  <v-app theme="dark">

    <v-app-bar app rounded>
      <v-tab></v-tab>
      <WideButton />
    </v-app-bar>

    <v-main>
      <v-navigation-drawer
        v-model="drawer"
        :rail="rail"
        permanent
        @click="rail = false"
      >
        <v-list-item
          prepend-avatar="/owl_b1.png"
          title="ToniM"
          nav
        >
          <template v-slot:append>
            <v-btn
              icon="mdi-chevron-left"
              variant="text"
              @click.stop="rail = !rail"
            ></v-btn>
          </template>
        </v-list-item>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-item prepend-icon="mdi-home-city" title="Home" value="home"></v-list-item>
          <!--<v-list-item prepend-icon="mdi-account" title="My Account" value="account"></v-list-item>
          <v-list-item prepend-icon="mdi-account-group-outline" title="Users" value="users"></v-list-item>-->
        </v-list>
      </v-navigation-drawer>

        <StakeDrop />
    </v-main>

    <v-footer app rounded class="d-flex justify-center align-center">
      <span>&copy; 2024 ToniM @ pryzmdata.zone</span>
    </v-footer>

  </v-app>
</template>

<script>
import StakeDrop from './views/StakeDrop.vue';
import WideButton from './components/TopBanner.vue'

export default {
  name: 'App',
  components: {
    StakeDrop,
    WideButton
  },

  data () {
      return {
        drawer: true,
        rail: true,
      }
    },
};
</script>

<style>
/* Add any custom styles here */
</style>
