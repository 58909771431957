<template>
    <v-text :style="{ fontSize: '24px' }">Total Rewards: <strong :style="{ color: 'orange' }">{{ formattedTotalReward }}</strong> PRYZM</v-text>
</template>
  
<script>
    export default {
    name: 'TotalReward',
    props: {
        totalReward: {
        type: Number,
        required: true
        }
    },
    computed: {
        formattedTotalReward() {
            return (typeof this.totalReward === 'number' && !isNaN(this.totalReward)) 
                ? this.totalReward.toFixed(2) 
                : '0';
        }
    }
};
</script>